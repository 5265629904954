import findStep from "./findStep";
import useBotControlStore from "../../../storeModules/useBotControlStore";
import useUserStore from "../../../storeModules/useUserStore";
import sendTag from "../../../utils/sendTag";

const researchSteps = [
  {
    name: "pesquisa-inicio",
    sender: "bot",
    text: "Agora me responda uma curiosidade",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("pesquisa-midia").index,
      });
    },
  },
  {
    name: "pesquisa-midia",
    sender: "bot",
    text: "Como você ficou sabendo do <b>Mycon</b>?",
    options: [
      {
        text: "TV",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "TV",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "FACEBOOK",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "FACEBOOK",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "INSTAGRAM",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "INSTAGRAM",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "YOUTUBE",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "YOUTUBE",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "GOOGLE",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "GOOGLE",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "E-MAIL",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "E-MAIL",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "INDICAÇÃO",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "INDICAÇÃO",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "LIVE",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "LIVE",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
      {
        text: "OUTROS",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              midia: "OUTROS",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-nota").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-nota",
    sender: "bot",
    text: "Qual a nota você dá para a experiência de compra do consórcio Mycon?",
    options: [
      {
        text: "1",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "1",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "2",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "2",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "3",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "3",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "4",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "4",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
      {
        text: "5",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatcompra: "5",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-humanos",
    sender: "bot",
    text: "Antes ou durante a simulação você precisou da ajuda do Time de Humanos?",
    options: [
      {
        text: "SIM",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              humano: "SIM",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos-pontuacao").index,
          });
        },
      },
      {
        text: "NÃO",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              humano: "NÃO",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-finalizada").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-agradecimento",
    sender: "bot",
    text: "Obrigado por avaliar!",
  },
  {
    name: "pesquisa-finalizada",
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `<b>${userBasicName}</b>, muito obrigado :)`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("pesquisa-algo-mais").index,
      });

      const userPesquisa = useUserStore.getState().pesquisa;

      sendTag({
        event: "Pesquisa",
        ecommerce: { ...userPesquisa },
      });
    },
  },
  {
    name: "pesquisa-algo-mais",
    sender: "bot",
    text: "Se precisar de algo mais, estou aqui!",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("pesquisa-ate-mais").index,
      });
    },
  },
  {
    name: "pesquisa-humanos-pontuacao",
    sender: "bot",
    text: `Qual nota você dá para o <b>atendimento</b> do Time de Humanos?`,
    options: [
      {
        text: "1",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "1",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-humanos-comentarios").index,
          });
        },
      },
      {
        text: "2",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "2",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-finalizada").index,
          });
        },
      },
      {
        text: "3",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "3",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-finalizada").index,
          });
        },
      },
      {
        text: "4",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "4",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-finalizada").index,
          });
        },
      },
      {
        text: "5",
        action: () => {
          const userData = useUserStore.getState();
          useUserStore.setState({
            ...userData,
            pesquisa: {
              ...userData.pesquisa,
              csatinsidesales: "5",
            },
          });

          useBotControlStore.setState({
            currentStep: findStep("pesquisa-finalizada").index,
          });
        },
      },
    ],
  },
  {
    name: "pesquisa-ate-mais",
    sender: "bot",
    text: "Até mais!",
  },
];

export default researchSteps;
