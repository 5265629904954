import React from "react";
import Slider from "react-slick";
import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomPrevArrow, CustomNextArrow } from "./arrow";

const ServicesCarousel = ({ options, onOptionClick }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {options.map((option, index) => (
          <div key={index} className="carousel-item">
            <button
              onClick={() => {
                onOptionClick(option);
                option.action();
              }}
              className="carousel-button"
            >
              <img
                src={require(`../../assets/icons/${option.imageThumb}`)}
                alt={option.text}
                width={200}
                className="carousel-icon"
              />
              <div className="options-title">
                {option.text}{" "}
                <div className="description-options">{option.description}</div>
              </div>
            </button>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ServicesCarousel;
