import findStep from "../findStep";
import useBotControlStore from "../../../../storeModules/useBotControlStore";
import useConsorcioStore from "../../../../storeModules/useConsorcioStore";
import sendTag from "../../../../utils/sendTag";
import { resetStoreComprarNovaCota } from "../../../../storeModules/resetStoreComprarNovaCota";

const pagamentoSteps = [
  {
    name: "payment-init",
    sender: "bot",
    text: "Para <b>concluir a contratação e garantir a sua oferta, efetue o pagamento da primeira parcela</b> e participe do próximo sorteio. 🥳",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-first-parcel").index,
      });
    },
  },
  {
    name: "payment-first-parcel",
    sender: "bot",
    text: () => {
      const parcela =
        useConsorcioStore.getState().selectedPlano.planoValorPagamento;

      return `Como prefere <b>pagar</b> a <b>1ª parcela</b> no valor de <b>${parcela.toLocaleString(
        "pt-BR",
        { style: "currency", currency: "BRL" }
      )}</b>?`;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("payment-options").index,
      });
    },
  },
  {
    name: "payment-options",
    sender: "bot",
    text: "Escolha uma opção:",
    options: () => {
      return useConsorcioStore
        .getState()
        .fetchedProposta.formasPagamentoDisponiveis.filter((code) =>
          [5, 0, 1].includes(code)
        )
        .map((code) => {
          const options = {
            5: {
              text: "PIX",
              action: () => {
                sendTag({ event: "PIX" });
                useBotControlStore.setState({
                  currentStep: findStep("pagamento-pix-registro").index,
                });
              },
            },
            0: {
              text: "CARTÃO (3X SEM JUROS)",
              action: () => {
                sendTag({ event: "Cartão de Crédito" });
                useBotControlStore.setState({
                  currentStep: findStep("payment-credit-card-message-secure")
                    .index,
                });
              },
            },
            1: {
              text: "BOLETO",
              action: () => {
                sendTag({ event: "Boleto Bancário" });
                useBotControlStore.setState({
                  currentStep: findStep("pagamento-boleto-registro").index,
                });
              },
            },
          };
          return options[code];
        });
    },
  },
  {
    name: "compra-finalizada",
    sender: "bot",
    text: "Você quer <b>comprar outra</b> cota?",
    options: [
      {
        text: "SIM",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("payment-buy-again-value").index,
          });
        },
      },
      {
        text: "NÃO",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("pesquisa-inicio").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-continue",
    sender: "bot",
    text: "Escolha uma das opções:",
    options: [
      {
        text: "CONTINUAR",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("payment-continue").index,
          });
        },
      },
    ],
  },
  {
    name: "payment-buy-again-value",
    sender: "bot",
    text: "Será com o mesmo valor de crédito e parcelas?",
    options: [
      {
        text: "NÃO",
        action: () => {
          resetStoreComprarNovaCota();

          useBotControlStore.setState({
            currentStep: findStep("lead-select-category").index,
          });
        },
      },
      {
        text: "SIM",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("proposal-confirm-data").index,
          });
        },
      },
    ],
  },
  {
    name: "alterar-pagamento",
    sender: "bot",
    text: "Escolha uma opção:",
    options: () => {
      return useConsorcioStore
        .getState()
        .fetchedProposta.formasPagamentoDisponiveis.filter((code) =>
          [5, 0, 1].includes(code)
        )
        .map((code) => {
          const options = {
            5: {
              text: "PIX",
              action: () => {
                sendTag({
                  event: "Metodo de Pagamento Alterado",
                  metodo_pagamento: "pix",
                });

                useBotControlStore.setState({
                  currentStep: findStep("pagamento-pix-registro").index,
                });
              },
            },
            0: {
              text: "CARTÃO (3X SEM JUROS)",
              action: () => {
                sendTag({
                  event: "Metodo de Pagamento Alterado",
                  metodo_pagamento: "cartao_de_credito",
                });

                useBotControlStore.setState({
                  currentStep: findStep("payment-credit-card-message-secure")
                    .index,
                });
              },
            },
            1: {
              text: "BOLETO",
              action: () => {
                sendTag({
                  event: "Metodo de Pagamento Alterado",
                  metodo_pagamento: "boleto_bancario",
                });

                useBotControlStore.setState({
                  currentStep: findStep("pagamento-boleto-registro").index,
                });
              },
            },
          };
          return options[code];
        });
    },
  },
];

export default pagamentoSteps;
