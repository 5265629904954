import useBotControlStore from "../../storeModules/useBotControlStore";
import useConsorcioStore from "../../storeModules/useConsorcioStore";
import { TipoSimulacaoEnum } from "../../utils/constants";
import { CategoriaPagamento } from "../../utils/constants";
import sendTag from "../../utils/sendTag";
import findStep from "../../screens/bot/stepModules/findStep";

const TypesOfPlans = () => {
  const options = [
    {
      text: "PLANO PAGA METADE",
      action: () => {
        const consorcioData = useConsorcioStore.getState();

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_PAGAMETADE][
              consorcioData.tipoBem
            ],
        });
        sendTag({ event: "Plano Paga Metade" });

        useBotControlStore.setState({
          currentStep: findStep("lead-credit-value").index,
        });
      },
    },
    {
      text: "PLANO INTEGRAL",
      action: () => {
        const consorcioData = useConsorcioStore.getState();

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_INTEGRAL][
              consorcioData.tipoBem
            ],
        });
        sendTag({ event: "Plano Integral" });

        useBotControlStore.setState({
          currentStep: findStep("lead-credit-value").index,
        });
      },
    },
  ];

  return options;
};

export default TypesOfPlans;
