import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import ErrorPage from "./screens/errorPage";
import offers from "./config/offers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App {...offers.CONECTA} />,
  },
  {
    path: "/app",
    element: <App {...offers.CONECTA} />,
  },
  {
    path: "/oferta",
    element: <App {...offers.CONECTA} />,
  },
  // {
  //   path: "/final-de-semana",
  //   element: <App {...offers.ANIVERSARIO_FINAL_DE_SEMANA} />,
  // },
  {
    path: "/performance",
    element: <App {...offers.PERFORMANCE} />,
  },
  {
    path: "/abandono-imovel",
    element: <App {...offers.ABANDONO_IMOVEL} />,
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

export default router;
