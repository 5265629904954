import useInitialConfigStore from "../../../storeModules/useInitialConfigStore";
import { TipoBotEnum } from "../../../utils/constants";
import { getApiHealthCheck } from "../../../api/requests";
import findStep from "./findStep";
import useBotControlStore from "../../../storeModules/useBotControlStore";

const copySteps = [
  {
    name: "copy-message-1",
    sender: "bot",
    text: () => {
      const { botType } = useInitialConfigStore.getState();
      const textBox = TipoBotEnum[botType];
      return textBox.textChat1;
    },
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("copy-message-2").index,
      });
    },
  },
  {
    name: "copy-message-2",
    sender: "bot",
    text: () => {
      const { botType } = useInitialConfigStore.getState();
      const textBox = TipoBotEnum[botType];
      return textBox.textChat2;
    },
    options: [
      {
        text: "SIMULE AGORA MESMO",
        action: async () => {
          getApiHealthCheck();
        },
      },
    ],
  },
];

export default copySteps;
