import useUserStore from "../../../storeModules/useUserStore";
import useBotControlStore from "../../../storeModules/useBotControlStore";
import findStep from "./findStep";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../../../utils/setLoadingMessage";
import { getCep } from "../../../api/requests";
import sendTag from "../../../utils/sendTag";

const cepErrorSteps = [
  {
    name: "cep-error",
    sender: "bot",
    text: "",
    isWaitingForFetch: true,
    action: () => {
      const { cepErrorAt } = useUserStore.getState();
      const nextStep =
        cepErrorAt === "cep-first-input"
          ? findStep("proposal-thanks-message").index
          : cepErrorAt === "update"
          ? findStep("proposal-confirm-data").index
          : findStep("proposal-confirm-data").index;
      const { logradouro, bairro } = useUserStore.getState().contrato;

      if (!logradouro) {
        useBotControlStore.setState({
          currentStep: findStep("cep-add-street").index,
        });

        return;
      }

      if (!bairro) {
        useBotControlStore.setState({
          currentStep: findStep("cep-add-neighborhood").index,
        });

        return;
      }

      useBotControlStore.setState({
        currentStep: nextStep,
      });
    },
  },
  {
    name: "cep-add-street",
    sender: "bot",
    inputType: "userStreet",
    text: "Insira o nome da <b>rua.</b>",
    isWaitingForFetch: true,
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("cep-error").index,
      });
    },
  },
  {
    name: "cep-add-neighborhood",
    sender: "bot",
    inputType: "userNeighborhood",
    text: "Insira o nome do <b>bairro.</b>",
    isWaitingForFetch: true,
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("cep-error").index,
      });
    },
  },
  {
    name: "cep-not-found",
    sender: "bot",
    text: "<b>Ops!</b> não encontrei esse CEP.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("cep-try-again").index,
      });
    },
  },
  {
    name: "cep-try-again",
    sender: "bot",
    text: `<b>Corrija o CEP</b> para tentar novamente.`,
    inputType: "userCep",
    action: async () => {
      setTimeout(() => {
        getCep();
      }, 500);
    },
  },
];

export default cepErrorSteps;
