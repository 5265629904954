import useInitialConfigStore from "../../../storeModules/useInitialConfigStore";
import {
  TipoSimulacaoEnum,
  CategoriaPagamento,
  valoresSimulacao,
} from "../../../utils/constants";
import findStep from "./findStep";
import useBotControlStore from "../../../storeModules/useBotControlStore";
import useUserStore from "../../../storeModules/useUserStore";
import useConsorcioStore from "../../../storeModules/useConsorcioStore";
import sendTag from "../../../utils/sendTag";
import TypesOfPlans from "../../../components/types-of-plans/types-of-plans";
import { getLead } from "../../../api/requests";
import ServicesPlans from "../../../components/servicesPlans/index";

const isSimulateAgain = () => {
  return useUserStore.getState().isSimulateAgain;
};

const handleGetLead = () => {
  getLead();
};

const leadSteps = [
  {
    name: "lead-select-category",
    sender: "bot",
    text: "Agora escolha o que <b>você deseja conquistar</b>:",
    isWaitingForFetch: true,
    useCarousel: true,
  },
  {
    name: "lead-basic-name",
    sender: "bot",
    text: "Como prefere que eu te <b>chame</b>?",
    inputType: "userBasicName",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-greetings").index,
      });
    },
  },
  {
    name: "lead-greetings",
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `Muito prazer, <b>${userBasicName} :)</b>`;
    },
    action: () => {
      const { tipoTabela } = useInitialConfigStore.getState();
      const { tipoBem } = useConsorcioStore.getState();

      if (tipoTabela === "integral") {
        useBotControlStore.setState({
          currentStep: findStep("lead-credit-value").index,
        });
        sendTag({ event: "Plano Integral" });

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_INTEGRAL][tipoBem],
        });
      } else if (tipoTabela === "paga_metade") {
        useBotControlStore.setState({
          currentStep: findStep("paga-metade-plan-type").index,
        });
        sendTag({ event: "Plano Paga Metade" });

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_PAGAMETADE][tipoBem],
        });
      } else if (tipoTabela === "completa" && tipoBem === "SERVICO") {
        useBotControlStore.setState({
          currentStep: findStep("lead-credit-value").index,
        });
      } else {
        useBotControlStore.setState({
          currentStep: findStep("lead-plans").index,
        });
      }
    },
  },
  {
    name: "paga-metade-plan-type",
    sender: "bot",
    text: `Vou te explicar como funciona o Plano Paga Metade :)`,
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("paga-metade-plan-video").index,
      });
    },
  },
  {
    name: "paga-metade-plan-video",
    sender: "bot",
    text: "",
    component: (
      <video
        width="100%"
        poster="https://chabot-cdn.mycon.com.br/mycon/paga-metade.jpg"
        controls
      >
        <source src="https://player.vimeo.com/progressive_redirect/playback/876194542/rendition/240p/file.mp4?loc=external&log_user=0&signature=984a12a5a6ece049d771e9109dec48d79460e695aa11fb66b507145fe60d1e8f" />
      </video>
    ),
    options: [
      {
        text: "CONTINUAR",
        action: () => {
          useBotControlStore.setState({
            currentStep: findStep("lead-credit-value").index,
          });
        },
      },
    ],
  },
  {
    name: "lead-plans",
    sender: "bot",
    text: "Opa, vamos partir pra essa conquista. Separei <b>dois planos que tenho disponíveis</b> para você escolher:",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-50-message").index,
      });
    },
  },
  {
    name: "lead-50-message",
    sender: "bot",
    text: "<b>Plano Paga Metade</b> <br/> Pague 50% do valor das parcelas até a contemplação. Quando esse dia chegar, o valor residual será diluído nas parcelas restantes.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-100-message").index,
      });
    },
  },
  {
    name: "lead-100-message",
    sender: "bot",
    text: "<b>Plano Integral</b><br/> Pague 100% do valor das suas parcelas até o final do prazo contratado em seu plano.",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-select-plan").index,
      });
    },
  },
  {
    name: "lead-select-plan",
    sender: "bot",
    text: "Escolha uma das opções:",
    options: TypesOfPlans(),
  },
  {
    name: "lead-credit-value",
    sender: "bot",
    text: "Qual é o <b>valor aproximado do crédito</b> que você precisa?",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-credit-value-add").index,
      });
    },
  },
  {
    name: "lead-credit-value-add",
    sender: "bot",
    inputType: "userCreditValue",
    text: () => {
      const { tipoPlano, tipoBem } = useConsorcioStore.getState();
      const valores = valoresSimulacao[tipoPlano]?.[tipoBem];
      return `Digite um valor entre:<br><br> <b>R$ ${valores.min.toLocaleString(
        "pt-BR",
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      )} a R$ ${valores.max.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}</b>`;
    },
    action: async () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-show-plan-message").index,
      });
    },
  },
  {
    name: "lead-show-plan-message",
    sender: "bot",
    text: "Vou te mostrar planos com <b>parcelas que cabem no seu bolso</b> e que não comprometem mais de <b>30%</b> da sua renda 😉.",
    action: async () => {
      const uservalorCredito = useUserStore.getState().basic.valorCredito;
      sendTag({
        event: "Valor Crédito",
        credit_value: uservalorCredito,
      });

      useBotControlStore.setState({
        currentStep: findStep("lead-show-plan-list").index,
      });
    },
  },
  {
    name: "lead-show-plan-list",
    sender: "bot",
    text: () => {
      return isSimulateAgain()
        ? null
        : "Para isso, informe a sua <b>renda mensal</b>, incluindo <b>renda extra</b> e <b>familiar</b>, se houver.";
    },
    action: () => {
      const nextStep = isSimulateAgain()
        ? findStep("proposal-plans-list-carousel-get").index
        : findStep("basico-valor-renda").index;

      useBotControlStore.setState({
        currentStep: nextStep,
      });
    },
  },
  {
    name: "basico-valor-renda",
    sender: "bot",
    text: "Digite o <b>valor da renda</b>:",
    inputType: "userRendaMensal",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-message-for-email").index,
      });
    },
  },
  {
    name: "sem-renda-extra",
    sender: "bot",
    text: () => `Então você precisa escolher um <b>crédito menor.</b>`,
    inputType: "userCreditValue",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("proposal-plans-list-carousel-get").index,
      });
    },
  },
  {
    name: "com-renda-extra",
    sender: "bot",
    text: () => `Informe a <b>renda extra</b> ou <b>familiar</b>:`,
    inputType: "userRendaExtra",
    action: () => {
      useBotControlStore.setState({
        currentStep: findStep("renda-extra-adicinada").index,
      });
    },
  },
  {
    name: "renda-extra-adicinada",
    sender: "bot",
    text: () =>
      `Ótimo 😀! Agora vou somar as rendas para encontrar a <b>parcela ideal</b>, tá bem?`,
    options: [
      {
        text: "Tá bem :)",
        action: () => {
          const botMessages = useBotControlStore.getState().messages;

          const newMessage = {
            sender: "bot",
            text: "Vou te mostrar planos com <b>parcelas que cabem no seu bolso</b> e que não comprometem mais de <b>30%</b> da sua renda 😉.",
          };

          useBotControlStore.setState({
            messages: [...botMessages, newMessage],
          });

          useBotControlStore.setState({
            currentStep: findStep("proposal-plans-list-carousel-get").index,
          });

          const userRendaMensal = useUserStore.getState().basic.rendaMensal;
          const userRendaExtra = useUserStore.getState().basic.rendaExtra;

          sendTag({
            event: "Renda Extra",
            renda: userRendaMensal,
            renda_extra: userRendaExtra,
          });
        },
      },
    ],
  },
  {
    name: "lead-message-for-email",
    sender: "bot",
    text: () => {
      const userBasicName = useUserStore.getState().basic.nome;
      return `Legal, <b>${userBasicName}</b>! Enquanto procuro os <b>melhores planos</b> pra você, me diz uma coisinha:`;
    },
    action: async () => {
      const userRendaMensal = useUserStore.getState().basic.rendaMensal;
      sendTag({ event: "Renda", income_value: userRendaMensal });

      useBotControlStore.setState({
        currentStep: findStep("lead-add-email").index,
      });
    },
  },
  {
    name: "lead-add-email",
    sender: "bot",
    text: "Qual o seu melhor <b>e-mail</b>?",
    inputType: "userEmail",
    action: async () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-add-phone").index,
      });
    },
  },
  {
    name: "lead-add-phone",
    sender: "bot",
    text: "Qual o número do seu <b>celular</b> (com DDD)?",
    inputType: "userTelefone",
    action: async () => {
      useBotControlStore.setState({
        currentStep: findStep("lead-get").index,
      });
    },
  },
  {
    name: "lead-get",
    sender: "bot",
    text: "",
    action: async () => {
      handleGetLead();
    },
  },
  {
    name: "servico-tabela-completa",
    sender: "bot",
    text: "",
    component: null,
    action: () => {
      const botMessages = useBotControlStore.getState().messages;

      const serviceMessage = {
        sender: "bot",
        text: `Opa, vamos partir pra essa conquista. Separei <b>dois planos que tenho disponíveis para você escolher:</b>`,
      };

      const planoIntegralMessage = {
        sender: "bot",
        text: `<b>Plano Integral</b><br>Pague 100% do valor das suas parcelas até o final do prazo contratado em seu plano.`,
      };

      useBotControlStore.setState({
        messages: [...botMessages, serviceMessage, planoIntegralMessage],
      });
    },
    options: ServicesPlans(),
  },
];

export default leadSteps;
