import React from "react";
import IconsWhatsapp from "../../assets/icons/ico-whatsapp.png";
import findStep from "../../screens/bot/stepModules/findStep";

const getClassName = (index, currentStep) => {
  const stepClassMapping = {
    [findStep("alterar-pagamento").index]: {
      0: "primary",
      1: "primary",
      2: "primary",
      3: "btwhatsapp",
    },
    [findStep("basico-valor-renda").index]: {
      0: "primary",
      1: "primary",
      2: "primary",
      3: "btwhatsapp",
    },
    [findStep("sem-renda-extra").index]: { 0: "primary", 1: "btwhatsapp" },
    [findStep("payment-pending").index]: { 0: "btwhatsapp" },
    [findStep("payment-refused").index]: {
      0: "primary",
      1: "primary",
      2: "primary",
      3: "btwhatsapp",
    },
    [findStep("error-general").index]: { 0: "primary", 1: "btwhatsapp" },
    [findStep("proposal-terms").index]: {
      1: "secondary",
      2: "secondary",
      3: "btwhatsapp",
    },
    [findStep("proposal-confirm-plan").index]: {
      1: "secondary",
      2: "secondary",
      3: "tertiary",
    },
    [findStep("proposal-confirm-infos").index]: {
      1: "secondary",
      2: "secondary",
      3: "tertiary",
    },
    [findStep("payment-pix-options").index]: {
      1: "primary",
      2: "secondary",
      3: "primary",
    },
    [findStep("payment-boleto-options").index]: {
      1: "primary",
      2: "secondary",
      3: "primary",
    },
    [findStep("payment-credit-card-select").index]: { 1: "secondary" },
  };

  const defaultClass = stepClassMapping[currentStep]?.[index] || "";
  return defaultClass;
};

const Options = ({ options, currentStep, handleOptionClick }) => {
  const convertedOptions = Array.isArray(options) ? options : options();

  return convertedOptions.map((option, index) => {
    const className = getClassName(index, currentStep);

    return (
      <button
        key={index}
        className={`option-button ${className} ${
          option.text === "FALAR COM OS ESPECIALISTAS" ? "btwhatsapp" : ""
        }`}
        onClick={() => {
          handleOptionClick(option);

          if (option.action) {
            option.action();
          }
        }}
      >
        {(className === "btwhatsapp" ||
          option.text === "FALAR COM OS ESPECIALISTAS") && (
          <figure className="image">
            <img
              alt="Atendimento via Whatsapp"
              className="Media"
              src={IconsWhatsapp}
            />
          </figure>
        )}
        <span>{option.text}</span>
      </button>
    );
  });
};

export default Options;
