import steps from "./steps";

// Current step is index or name
const findStep = (currentStep) => {
  const objectSteps = steps.reduce((acc, step, index) => {
    step.index = index;
    acc[step.name || index] = step;
    return acc;
  }, {});

  // console.log(
  //   "currentStep --->",
  //   Object.values(objectSteps).find((step) => step.index === 90)
  // );

  return objectSteps[currentStep];
};

export default findStep;
