import "../bot.scss";
import errorSteps from "./errorSteps";
import pagamentoPixSteps from "./pagamento/pagamentoPixSteps";
import pagamentoBoletoSteps from "./pagamento/pagamentoBoletoSteps";
import pagamentoCartaoSteps from "./pagamento/pagamentoCartaoSteps";
import cepErrorSteps from "./cepErrorSteps";
import copySteps from "./copySteps";
import leadSteps from "./leadSteps";
import proposalSteps from "./proposalSteps";
import pagamentoSteps from "./pagamento/pagamentoSteps";
import researchSteps from "./researchSteps";

const steps = [
  ...copySteps,
  ...leadSteps,
  ...proposalSteps,
  ...pagamentoSteps,
  ...researchSteps,
  ...pagamentoCartaoSteps,
  ...pagamentoPixSteps,
  ...pagamentoBoletoSteps,
  ...errorSteps,
  ...cepErrorSteps,
];

export default steps;
