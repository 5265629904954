import useConsorcioStore from "../../storeModules/useConsorcioStore";
import { TipoSimulacaoEnum } from "../../utils/constants";
import { CategoriaPagamento } from "../../utils/constants";
import sendTag from "../../utils/sendTag";
import useBotControlStore from "../../storeModules/useBotControlStore";
import findStep from "../../screens/bot/stepModules/findStep";

const ServicesPlans = () => {
  const options = [
    {
      text: "SIMULAR PLANO INTEGRAL",
      action: () => {
        const consorcioData = useConsorcioStore.getState();

        useConsorcioStore.setState({
          tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
          categoriaId:
            CategoriaPagamento[TipoSimulacaoEnum.CREDITO_INTEGRAL][
              consorcioData.tipoBem
            ],
        });
        sendTag({ event: "Plano Integral" });

        useBotControlStore.setState({
          currentStep: findStep("lead-basic-name").index,
        });
      },
    },
    {
      text: "ESCOLHER OUTRO PRODUTO",
      action: () => {
        useBotControlStore.setState({
          currentStep: findStep("lead-select-category").index,
        });
      },
    },
  ];

  return options;
};

export default ServicesPlans;
