import { TipoSimulacaoEnum, BOT_NAME } from "../utils/constants";

const offers = {
  DEFAULT: {
    botType: BOT_NAME.MYCON,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
    campanhaId: 2,
    compraId: 161,
    empresaId: 90,
    desconto: false,
    tipoTabela: "completa",
    brand: "Site Mycon",
    bannerSrc:
      "https://chatbot-cdn.mycon.com.br/mycon/chat-conecta-junho-2024.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  LIVE: {
    botType: BOT_NAME.LIVE,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
    campanhaId: 851,
    compraId: 123,
    empresaId: 50,
    desconto: true,
    tipoTabela: "paga_metade",
    brand: "Site Mycon",
    bannerSrc:
      "https://chatbot-cdn.mycon.com.br/mycon/banner-mycon-acao-oferta-taxa-promo.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  ANIVERSARIO: {
    botType: BOT_NAME.ANIVERSARIO,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description:
      "50% mais barato que os outros consórcios. Compre seu Consórcio de Imóvel, Auto, Moto ou Serviços. O Mycon é o Melhor Negócio!",
    indexar: "index,follow",
    campanhaId: 851,
    compraId: 164,
    empresaId: 93,
    desconto: true,
    tipoTabela: "paga_metade",
    brand: "Site Mycon",
    bannerSrc:
      "https://chabot-cdn.mycon.com.br/mycon/banner-aniversario-mycon-acao-mes.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
  },
  PERFORMANCE: {
    botType: BOT_NAME.PERFORMANCE,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 759,
    compraId: 176,
    empresaId: 102,
    desconto: true,
    tipoTabela: "completa",
    brand: "Site Mycon Performance",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/banner-cliente-vip-2025.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  ABANDONO_IMOVEL: {
    botType: BOT_NAME.ABANDONO_IMOVEL,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 759,
    compraId: 176,
    empresaId: 102,
    desconto: true,
    tipoTabela: "completa",
    brand: "Site Mycon Performance",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/banner_imovel-17-01-25.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  ANIVERSARIO_FINAL_DE_SEMANA: {
    botType: BOT_NAME.ANIVERSARIO_FINAL_DE_SEMANA,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 170,
    empresaId: 95,
    desconto: true,
    tipoTabela: "paga_metade",
    brand: "Site Mycon",
    bannerSrc:
      "https://chabot-cdn.mycon.com.br/mycon/banner-aniversario-mycon-taxa-promo-11.99.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
  },
  FINDE_TURBO: {
    botType: BOT_NAME.FINDE_TURBO,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 83,
    empresaId: 40,
    desconto: true,
    tipoTabela: "paga_metade",
    brand: "Site Mycon",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/banner_turbo_27-12-24.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
  },
  CONECTA: {
    botType: BOT_NAME.CONECTA,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 164,
    empresaId: 93,
    desconto: true,
    tipoTabela: "completa",
    brand: "Site Mycon",
    bannerSrc:
      "https://chabot-cdn.consorciosmycon.com.br/mycon/banner_conecta_08-01-2025.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_INTEGRAL,
  },
  CRM: {
    botType: BOT_NAME.CRM,
    title: "Mycon, o consórcio com a menor taxa do Brasil",
    description: "Oferta para você ir com tudo na sua conquista com o Mycon.",
    indexar: "noindex,nofollow",
    campanhaId: 851,
    compraId: 123,
    empresaId: 50,
    desconto: true,
    tipoTabela: "paga_metade",
    brand: "Site Mycon",
    bannerSrc: "https://chabot-cdn.mycon.com.br/mycon/banner_crm_20.12.24.png",
    tipoPlano: TipoSimulacaoEnum.CREDITO_PAGAMETADE,
  },
};

export default offers;
