import { api, apiWithkey } from "./axios";
import {
  baseApiURL,
  healthyCheckApi,
  saveLeadApi,
  plansApi,
  cepApi,
  getPropostaApi,
  pagamentoCartaoApi,
  pagamentoBoletoApi,
  pagamentoPixApi,
  planDetailsApi,
} from "./endpoints";
import useUserStore from "../storeModules/useUserStore";
import useInitialConfigStore from "../storeModules/useInitialConfigStore";
import useConsorcioStore from "../storeModules/useConsorcioStore";
import {
  setLoadingMessage,
  removeLoadingMessage,
} from "../utils/setLoadingMessage";
import useBotControlStore from "../storeModules/useBotControlStore";
import sendTag from "../utils/sendTag";
import sleepAwait from "../utils/sleepAwait";
import findStep from "../screens/bot/stepModules/findStep";
import sha256 from "crypto-js/sha256";
import addPhoneDatalayerMask from "../utils/addPhoneDatalayerMask";

const fakeLoad = useInitialConfigStore.getState().fakeLoad;

const getApiHealthCheck = async () => {
  const url = `${baseApiURL}${healthyCheckApi}`;

  setLoadingMessage();

  api
    .get(url)
    .then(async (response) => {
      sendTag({ event: "Começar" });

      await sleepAwait(fakeLoad);

      if (response) {
        useBotControlStore.setState({
          currentStep: findStep("lead-select-category").index,
        });
      } else {
        useBotControlStore.setState({
          currentStep: findStep("error-general").index,
        });
      }
    })
    .catch(() => {
      useBotControlStore.setState({
        currentStep: findStep("error-general").index,
      });
    })
    .finally(() => {
      removeLoadingMessage();
    });
};

const getLead = async () => {
  setLoadingMessage();

  const url = `${baseApiURL}${saveLeadApi}`;

  const { campanhaId, compraId, empresaId, categoriaId, tipo, referralcode } =
    useInitialConfigStore.getState();

  const { nome, email, telefone, rendaMensal } = useUserStore.getState().basic;

  const props = {
    nome,
    email,
    telefone,
    rendaMensal,
    campanhaId,
    compraId,
    categoriaId,
    tipo,
    empresaId,
    referralCode: referralcode,
  };

  apiWithkey
    .post(url, props)
    .then(async (response) => {
      const { data } = response.data;

      useUserStore.setState({
        lead: {
          LeadID: data.id,
          LeadToken: data.LeadToken,
        },
      });

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      const userNome = useUserStore.getState().basic.nome;
      const userEmail = useUserStore.getState().basic.email;
      const userTelefone = useUserStore.getState().basic.telefone;
      const userLeadID = useUserStore.getState().lead.LeadID;

      const hashedEmail = sha256(userEmail).toString();
      const hashedTelefone = sha256(
        addPhoneDatalayerMask(userTelefone)
      ).toString();

      sendTag({
        event: "Lead Save",
        email: hashedEmail,
        celular: hashedTelefone,
        lead_id: userLeadID,
        email_cliente: userEmail,
        celular_cliente: addPhoneDatalayerMask(userTelefone),
        nome: userNome,
      });

      useBotControlStore.setState({
        currentStep: findStep("proposal-plans-list-carousel-get").index,
      });

      return response;
    })
    .catch(() => {
      useBotControlStore.setState({
        currentStep: findStep("payment-pix-copy-paste-show").index,
      });

      removeLoadingMessage();
    });
};

const getPlans = async () => {
  setLoadingMessage();

  const url = `${baseApiURL}${plansApi}`;

  const { empresaId } = useInitialConfigStore.getState();
  const { categoriaId } = useConsorcioStore.getState();
  const { valorCredito, rendaMensal, rendaExtra } =
    useUserStore.getState().basic;
  const { LeadID } = useUserStore.getState().lead;

  const props = {
    categoriaId,
    empresaId,
    valorCredito,
    leadID: LeadID,
    valorParcelaIdeal: (rendaMensal + rendaExtra) * 0.3,
  };

  return apiWithkey
    .post(url, props)
    .then(async (response) => {
      const fetchedPlans = response.data.data.planos;
      const consorcioData = useConsorcioStore.getState();

      sendTag({ event: "Ver Planos" });

      if (fetchedPlans.length) {
        useConsorcioStore.setState({
          ...consorcioData,
          fetchedPlans,
        });

        await sleepAwait(fakeLoad);

        useBotControlStore.setState({
          currentStep: findStep("plans-list-select").index,
        });
      }
    })
    .catch((error) => {
      if (
        error.response.data.errors.includes(
          "Não foram encontrados planos para esta empresa"
        )
      ) {
        useBotControlStore.setState({
          currentStep: findStep("error-no-plans").index,
        });
      } else if (
        error.response.data.errors.includes(
          "Não foram encontrados planos para sua renda"
        )
      ) {
        useBotControlStore.setState({
          currentStep: findStep("error-renda-insuficiente").index,
        });
      } else {
        useBotControlStore.setState({
          currentStep: findStep("payment-pix-copy-paste-show").index,
        });
      }
    })
    .finally(() => {
      removeLoadingMessage();
    });
};

const getCep = async () => {
  setLoadingMessage();

  const { cep } = useUserStore.getState().contrato;
  const url = `${baseApiURL}${cepApi}?cep=${cep}`;
  const storeCepErrorAt = useUserStore.getState().cepErrorAt;

  return apiWithkey
    .get(url)
    .then(async (response) => {
      const address = response.data.data;
      const userCep = useUserStore.getState().contrato.cep;
      const userContratoData = useUserStore.getState().contrato;
      const userBasicData = useUserStore.getState().basic;
      const userLeadData = useUserStore.getState().lead;

      if (storeCepErrorAt !== "update") {
        sendTag({
          event: "Cadastro CEP",
          cep: userCep,
        });
      }

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      if (!address) {
        useBotControlStore.setState({
          currentStep: findStep("cep-not-found").index,
        });
        return;
      }

      if (address && address.cep) {
        useUserStore.setState({
          basic: {
            ...userBasicData,
          },
          lead: {
            ...userLeadData,
          },
          contrato: {
            ...userContratoData,
            bairro: address.bairro,
            cep: address.cep,
            cidade: address.cidade,
            complemento: address.complemento,
            estado: address.estado,
            logradouro: address.logradouro,
            numero: address.numero,
          },
        });
      }

      if (!address.logradouro || !address.bairro) {
        useBotControlStore.setState({
          currentStep: findStep("cep-error").index,
        });

        return;
      }

      useBotControlStore.setState({
        currentStep:
          storeCepErrorAt === "cep-first-input"
            ? findStep("proposal-thanks-message").index
            : findStep("proposal-confirm-data").index,
      });

      return response;
    })
    .catch(() => {
      useBotControlStore.setState({
        currentStep: findStep("cep-not-found").index,
      });
      removeLoadingMessage();
    });
};

const getProposta = async () => {
  const url = `${baseApiURL}${getPropostaApi}`;

  const {
    bairro,
    cep,
    cidade,
    estado,
    logradouro,
    nomeRazao,
    nascimentoFundacao,
    cpfCnpj,
  } = useUserStore.getState().contrato;

  const { telefone, rendaMensal, email } = useUserStore.getState().basic;
  const { LeadID } = useUserStore.getState().lead;
  const { planoID, planoMeses, bemID } =
    useConsorcioStore.getState().selectedPlano;
  const { empresaId } = useInitialConfigStore.getState();
  const { tipoPessoa } = useUserStore.getState().contrato;

  const props = {
    bairro,
    bemID: bemID,
    cep,
    cidade,
    complemento: "",
    cpfCnpj,
    email,
    empresaId,
    estado,
    leadId: LeadID.toString(),
    logradouro,
    nascimentoFundacao: new Date(nascimentoFundacao),
    nome: nomeRazao,
    nomeRazao,
    numero: "0",
    planoID: planoID,
    planoMeses: planoMeses,
    rendaFaturamento: rendaMensal.toString(),
    rendaMensal: rendaMensal.toString(),
    telefone,
    telefoneCelular: telefone,
    termos: true,
    tipoPessoa: tipoPessoa,
  };

  return apiWithkey
    .post(url, props)
    .then((response) => {
      const consorcioData = useConsorcioStore.getState();
      const { data } = response.data;

      if (data && data.propostaID) {
        useConsorcioStore.setState({
          ...consorcioData,
          fetchedProposta: data,
        });
      }
      useBotControlStore.setState({
        currentStep: findStep("proposal-wait").index,
      });
    })
    .catch((error) => {
      if (
        error.response.data.errors.includes("O CEP informado, não é válido!")
      ) {
        useBotControlStore.setState({
          currentStep: findStep("cep-not-found").index,
        });
        useUserStore.setState({
          cepErrorAt: "proposta",
        });
        return;
      }

      useBotControlStore.setState({
        currentStep: findStep("error-general").index,
      });
    });
};

const sendPaymentCartao = async (props) => {
  const url = `${baseApiURL}${pagamentoCartaoApi}`;

  return apiWithkey.post(url, props);
};

const getPaymentBoleto = async () => {
  setLoadingMessage();

  const { propostaToken } = useConsorcioStore.getState().fetchedProposta;

  const url = `${baseApiURL}${pagamentoBoletoApi}`;
  const data = JSON.stringify(propostaToken);

  const config = {
    method: "post",
    url,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    data,
  };

  return apiWithkey
    .request(config)
    .then(async (response) => {
      const { data } = response.data;
      const userPaymentData = useUserStore.getState().payment;

      useUserStore.setState({
        payment: { ...userPaymentData, boleto: data },
      });

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      useBotControlStore.setState({
        currentStep: findStep("payment-congratulations").index,
      });
    })
    .catch((error) => {
      console.log("error --->", error);

      useBotControlStore.setState({
        currentStep: findStep("pagamento-boleto-tentar-novamente").index,
      });
      removeLoadingMessage();
    });
};

const getPaymentPix = async () => {
  setLoadingMessage();

  const { propostaToken } = useConsorcioStore.getState().fetchedProposta;

  const url = `${baseApiURL}${pagamentoPixApi}`;
  const data = JSON.stringify(propostaToken);

  const config = {
    method: "post",
    url,
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    data,
  };

  return apiWithkey
    .request(config)
    .then(async (response) => {
      const { data } = response.data;
      const userPaymentData = useUserStore.getState().payment;

      useUserStore.setState({
        payment: { ...userPaymentData, pix: data },
      });

      await sleepAwait(fakeLoad);
      removeLoadingMessage();

      useBotControlStore.setState({
        currentStep: findStep("payment-success-conquest").index,
      });
    })
    .catch((error) => {
      console.log("error --->", error);

      useBotControlStore.setState({
        currentStep: findStep("pagamento-pix-tentar-novamente").index,
      });
      removeLoadingMessage();
    });
};

const getPlanDetails = async () => {
  const url = `${baseApiURL}${planDetailsApi}`;

  const { planoID, bemID } = useConsorcioStore.getState().selectedPlano;
  const { LeadID } = useUserStore.getState().lead;
  const { empresaId } = useInitialConfigStore.getState();

  const props = {
    empresaID: empresaId,
    leadID: LeadID,
    planoID: planoID,
    bemID: bemID,
  };

  return apiWithkey.post(url, props);
};

export {
  getApiHealthCheck,
  getLead,
  getPlans,
  getCep,
  getProposta,
  sendPaymentCartao,
  getPaymentBoleto,
  getPaymentPix,
  getPlanDetails,
};
