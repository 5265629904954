import React from "react";

const InputMessage = ({ errors, inputType }) => {
  return (
    <span
      data-testid="input-error-message"
      className={`input-hint ${
        errors[inputType]?.message ? "input-hint__error" : ""
      }`}
    >
      {errors[inputType]?.message
        ? errors[inputType]?.message
        : "Aperte ENTER para enviar:"}
    </span>
  );
};

export default InputMessage;
