import React from "react";
import "./styles.scss";

const LoadingDots = ({ size, color }) => {
  let classSize = "";

  if (size === "small") {
    classSize = "loading-dots--small";
  } else if (size === "medium") {
    classSize = "loading-dots--medium";
  } else if (size === "large") {
    classSize = "loading-dots--large";
  }

  return (
    <div
      data-testid="loading-dots"
      className={`loading-dots ${classSize} ${
        color === "primary"
          ? "loading-dots--color-primary"
          : "loading-dots--color-white"
      }`}
    ></div>
  );
};

export default LoadingDots;
