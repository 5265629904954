import React, { useState } from "react";
import logo from "../../assets/images/logo.svg";
import menuIcon from "../../assets/icons/menu-icon.svg";
import closeIcon from "../../assets/icons/close.svg";
import "./header.scss";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header-container">
      <a
        href="https://www.mycon.com.br/"
        target="_blank"
        rel="noopener noreferrer">
        <img src={logo} alt="Icon" height={65} />
      </a>
      <img
        src={menuOpen ? closeIcon : menuIcon}
        alt="Menu Icon"
        className="menu-icon"
        onClick={toggleMenu}
      />
      <nav className={`header-menu ${menuOpen ? "open" : ""}`}>
        <ul className="menu-list">
          <img src={logo} alt="Icon" height={65} />
          <li>
            <a href="https://www.mycon.com.br/">Home</a>
          </li>
          <li>
            <a href="https://blog.mycon.com.br/">Blog Mycon</a>
          </li>
          <li>
            <a href="https://www.mycon.com.br/porque-mycon/">
              Bê-á-bá do Consórcio
            </a>
          </li>
          <li>
            <a href="https://www.mycon.com.br/por-que-o-mycon/">
              Por que Mycon?
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/playlist?list=PLfFHu65zAul5JVjlGh2yn2n8ACJZG9e6f/">
              MyConquista
            </a>
          </li>
          <li>
            <a href="https://www.mycon.com.br/duvidas/">Dúvidas?</a>
          </li>
          <li>
            <a href="https://mycon.gupy.io/">Trabalhe no Mycon</a>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
